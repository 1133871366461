import { Suspense } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useUser } from "context/AuthContext";
import Paths from "./Paths";

import Loader from "components/general/Loader";
import MainLayout from "layouts/MainLayout";
import NotLoggedInLayout from "layouts/NotLoggedInLayout";

export default function ProtectedRoute({ need_auth }) {

   const user = useUser();

   if ( need_auth ) {
      return user?.id ? (
         <MainLayout> 
            <Suspense fallback={ <Loader style={{ margin: "auto" }}/>}> 
               <Outlet/> 
            </Suspense> 
         </MainLayout>
       ) : <Navigate to={ Paths.Auth.Login }/> 
   } 
   
   if ( !need_auth && user?.id ) return <Navigate to={ Paths.Issue.List }/>

   return (
      <NotLoggedInLayout> 
         <Suspense fallback={ <Loader style={{ margin: "auto" }}/>}> <Outlet/> </Suspense> 
      </NotLoggedInLayout>
   )
}