import AuthContextProvider from "context/AuthContext";
import Routes from "router/Routes";

import "styles/main.scss";
import "styles/toast.scss";

export default function App() {
	return (
		<AuthContextProvider>
         <Routes/>
      </AuthContextProvider>
	)
}