import UserAPI from "api/UserAPI";
import default_avatar from "img/default-avatar.png";
import "./user-photo-name.scss";

export default function UserPhotoName({ user, hide_full_name, show_company }) {
	
	const avatar_url = UserAPI.getAvatarURl( user.id, user.avatar_file_id );

	return (
		<div className="user-photo-name">
			<div style={{ backgroundImage: `url(${ avatar_url || default_avatar })` }}/>
			{ !hide_full_name && 
            <span>
               { user?.full_name }
               { show_company && user?.company_name && 
                  <> ({ user?.company_name }) </> 
               } 
            </span> 
         }
		</div>
	)
}